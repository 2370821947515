@font-face {
  font-family: "CustomFont";
  src: url("../assets/fonts/welcome/TW.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.welcome-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "CustomFont", sans-serif;
  background-color: #1e1e19;
  background: url("../assets/images/bikers.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  gap: 60px;
}

.welcome-text {
  position: relative;
  display: flex;
  gap: 1.5rem;
  opacity: 0;
  animation: fadeIn 2s forwards;
  top: 140px;
  left: 0;
  transform: translate(0, 50%);
}

.scroll-animation {
  position: relative;
  max-width: 9%;
  height: auto;
  opacity: 0;
  animation: fadeInScroll 6s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInScroll {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(190%);
  }
}

.word {
  font-size: 6rem;
  color: #fff;
}

.word:nth-child(1) {
  animation-delay: 0.3s;
}

.word:nth-child(2) {
  animation-delay: 0.6s;
}

.word:nth-child(3) {
  animation-delay: 0.9s;
}

.hamid-text {
  position: relative;
  bottom: -400px; /* Start from off-screen */
  opacity: 0;
  transition: all 1s ease-out;
}

.hamid-text.visible {
  bottom: -80px; /* Final position beneath the "TEHRAN TO BALI" text */
  opacity: 1;
}

.hamid-word {
  color: #fff;
  font-size: 1.2rem;
  font-family: Arial, Helvetica, sans-serif;
}

/* General mobile adjustments */
@media (max-width: 768px) {
  /* General container adjustments */
  .welcome-container {
    padding: 20px;
  }

  /* Navbar adjustments for mobile view */
  .navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .navbar .menu {
    display: none; /* Hide regular menu */
  }
  .navbar .hamburger-menu {
    display: block;
    font-size: 24px;
    cursor: pointer;
  }

  .scroll-animation {
    max-width: 15%;
    top: 0px;
  }

  /* Text adjustments */
  .word {
    font-size: 2.9em; /* Scale down header sizes */
    text-align: center;
  }
  p {
    font-size: 1em;
  }

  /* Image adjustments */
  img {
    width: 100%;
    height: auto;
  }

  /* Footer adjustments */
  .footer {
    text-align: center;
    padding: 10px;
    font-size: 0.9em;
  }
}

/* Smaller devices (e.g., phones) */
/* @media (max-width: 480px) {
  .word {
    font-size: 0.5em;
  }
  .container,
  .main-content {
    width: 100%;
    padding: 5px;
  }
  .footer {
    font-size: 0.8em;
  }
} */
