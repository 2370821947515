.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  max-width: 100px;
  /* background: #2b2b2b; */
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); */
  transition: max-width 2s ease, box-shadow 0.3s ease;
  cursor: pointer;
  overflow: hidden;
}


.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  padding: 0 10px;
  overflow: hidden;
}

.logo-container img {
  width: 80%;
  height: auto;
  transition: transform 0.3s ease;
}

.logo-container:hover img {
  transform: scale(1.25);
}

.text-container {
  text-align: center;
  color: #fff;
  font-size: 1.2rem;
  padding: 10px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  opacity: 0;
  transition: display 5s ease-in-out, opacity 3s ease;
  width: 200px;
  display: none;
}

.card-container:hover .text-container {
  opacity: 1;
}

/* General mobile adjustments */
@media only screen and (max-width: 768px) {
    /* General container adjustments */
    .container, .main-content {
        width: 90%;
        margin: 0 auto;
        padding: 10px;
    }

    /* Navbar adjustments for mobile view */
    .navbar {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .navbar .menu {
        display: none; /* Hide regular menu */
    }
    .navbar .hamburger-menu {
        display: block;
        font-size: 24px;
        cursor: pointer;
    }
    
    /* Text adjustments */
    h1, h2, h3, h4 {
        font-size: 1.5em; /* Scale down header sizes */
        text-align: center;
    }
    p {
        font-size: 1em;
    }

    /* Image adjustments */
    img {
        width: 100%;
        height: auto;
    }

    /* Footer adjustments */
    .footer {
        text-align: center;
        padding: 10px;
        font-size: 0.9em;
    }
}

/* Smaller devices (e.g., phones) */
@media only screen and (max-width: 480px) {
    h1, h2, h3, h4 {
        font-size: 1.2em;
    }
    .container, .main-content {
        width: 100%;
        padding: 5px;
    }
    .footer {
        font-size: 0.8em;
    }
}
