/* Hamburger Icon Styles */
.hamburger {
  position: fixed;
  top: 30px;
  right: 40px;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 35px;
  height: 30px;
  cursor: pointer;
}

/* Full Screen Overlay Menu */
.menu-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(30, 30, 25, 0.98);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s;
  z-index: 1000;
}

.menu-open {
  opacity: 1;
  visibility: visible;
}

/* Menu List */
.navList {
  list-style: none;
  text-align: center;
}

.navItem {
  margin: 20px 0;
  font-size: 4rem;
  color: #f5f5f5;
  font-family: "TitleFont", 'sans-serif';
  cursor: pointer;
  transition: color 0.3s;
}

.navItem:hover {
  color: #fb6813;
}

/* General mobile adjustments */
@media only screen and (max-width: 768px) {
    /* General container adjustments */
    .container, .main-content {
        width: 90%;
        margin: 0 auto;
        padding: 10px;
    }

    /* Navbar adjustments for mobile view */
    .navbar {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .navbar .menu {
        display: none; /* Hide regular menu */
    }
    .navbar .hamburger-menu {
        display: block;
        font-size: 24px;
        cursor: pointer;
    }
    
    /* Text adjustments */
    h1, h2, h3, h4 {
        font-size: 1.5em; /* Scale down header sizes */
        text-align: center;
    }
    p {
        font-size: 1em;
    }

    /* Image adjustments */
    img {
        width: 100%;
        height: auto;
    }

    /* Footer adjustments */
    .footer {
        text-align: center;
        padding: 10px;
        font-size: 0.9em;
    }
}

/* Smaller devices (e.g., phones) */
@media only screen and (max-width: 480px) {
    h1, h2, h3, h4 {
        font-size: 1.2em;
    }
    .container, .main-content {
        width: 100%;
        padding: 5px;
    }
    .footer {
        font-size: 0.8em;
    }
}
