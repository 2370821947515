.sponsors-container {
  background-color: #1e1e19;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.oval-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 25px;
  /* background-color: rgba(49, 49, 48, 0.8); */
  border-radius: 60px;
  /* box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); */
  /* transition: background-color 0.3s ease, box-shadow 0.3s ease; */
}


/* General mobile adjustments */
@media only screen and (max-width: 768px) {
    /* General container adjustments */
    .container, .main-content {
        width: 90%;
        margin: 0 auto;
        padding: 10px;
    }

    /* Navbar adjustments for mobile view */
    .navbar {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .navbar .menu {
        display: none; /* Hide regular menu */
    }
    .navbar .hamburger-menu {
        display: block;
        font-size: 24px;
        cursor: pointer;
    }
    
    /* Text adjustments */
    h1, h2, h3, h4 {
        font-size: 1.5em; /* Scale down header sizes */
        text-align: center;
    }
    p {
        font-size: 1em;
    }

    /* Image adjustments */
    img {
        width: 100%;
        height: auto;
    }

    /* Footer adjustments */
    .footer {
        text-align: center;
        padding: 10px;
        font-size: 0.9em;
    }
}

/* Smaller devices (e.g., phones) */
@media only screen and (max-width: 480px) {
    h1, h2, h3, h4 {
        font-size: 1.2em;
    }
    .container, .main-content {
        width: 100%;
        padding: 5px;
    }
    .footer {
        font-size: 0.8em;
    }
}
