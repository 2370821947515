@font-face {
  font-family: "TitleFont";
  src: url("../assets/fonts/routes/Lalezar-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.routes-container {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 0 50px;
}

.routes-container::before {
  content: "";
  position: absolute;
  top: -35px;
  left: -35px;
  width: calc(100% + 70px);
  height: calc(100% + 70px);
  background: url("../assets/images/routes-bg.jpg") center center / cover
    no-repeat;
  filter: blur(10px);
  z-index: -1;
}

.title-section {
  font-size: 3.5rem;
  font-family: "TitleFont", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
  z-index: 1;
}

.journey-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 110px;
  z-index: 1;
  padding: 50px;
}

.road-section {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.road {
  max-width: 100%;
  height: auto;
}

.bike {
  position: absolute;
  transform: translateX(-50%);
  width: 12%;
  height: auto;
  transition: left 0.5s ease-in-out;
}

.city-icons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  gap: 20px;
  z-index: 1;
}

.country-info-mobile {
  display: none;
}
.arrow-button {
  display: none;
}
/* General mobile adjustments */
@media only screen and (max-width: 768px) {
  /* General container adjustments */
  .routes-container {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    padding: 0 30px;
    min-height: 90vh;
  }

  .journey-section {
    display: none;
  }

  .country-info-mobile {
    display: flex;
  }
  .country-info-desktop {
    display: none;
  }
  .arrow-button {
    display: flex;
    background: none;
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .left-arrow {
    left: 10px;
  }

  .right-arrow {
    right: 10px;
  }
}

/* Smaller devices (e.g., phones) */
@media only screen and (max-width: 480px) {
  .arrow-button {
    display: flex;
    background: none;
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .left-arrow {
    left: 10px;
  }

  .right-arrow {
    right: 10px;
  }
}
