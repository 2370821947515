.city-icon {
  width: 60px;
  height: 60px;
}

.city-icon-svg {
  width: 100%;
  height: 100%;
  transition: fill 0.3s ease-in-out, transform 0.2s ease-in-out;
}

.city-icon-svg:hover {
  transform: scale(1.2);
  transition: fill 0.3s ease-in-out, transform 0.3s ease-in-out; /* Added transform to transition */
}

.city-icon-svg:hover {
  transform: scale(
    1.3
  ); /* Scale the SVG to 1.3 times its original size on hover */
}

/* General mobile adjustments */
@media only screen and (max-width: 768px) {
    /* General container adjustments */
    .container, .main-content {
        width: 90%;
        margin: 0 auto;
        padding: 10px;
    }

    /* Navbar adjustments for mobile view */
    .navbar {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .navbar .menu {
        display: none; /* Hide regular menu */
    }
    .navbar .hamburger-menu {
        display: block;
        font-size: 24px;
        cursor: pointer;
    }
    
    /* Text adjustments */
    h1, h2, h3, h4 {
        font-size: 1.5em; /* Scale down header sizes */
        text-align: center;
    }
    p {
        font-size: 1em;
    }

    /* Image adjustments */
    img {
        width: 100%;
        height: auto;
    }

    /* Footer adjustments */
    .footer {
        text-align: center;
        padding: 10px;
        font-size: 0.9em;
    }
}

/* Smaller devices (e.g., phones) */
@media only screen and (max-width: 480px) {
    h1, h2, h3, h4 {
        font-size: 1.2em;
    }
    .container, .main-content {
        width: 100%;
        padding: 5px;
    }
    .footer {
        font-size: 0.8em;
    }
}
