.container {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: right;
}

.button {
  padding: 10px 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  border: none;
  box-shadow: 0px 2px 4px #4b4b4b;
  position: relative;
}

.button-text {
  transition: transform 200ms ease;
}

.button-text.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

.icon-send {
  margin-left: 10px;
  transition: 1s ease;
  width: 24px;
  height: 24px;
}

.icon-send.sending {
  transform: scale(0.7) translateX(800px);
}

.icon-check {
  margin-right: 10px;
  opacity: 0;
  position: absolute;
  transition: 200ms ease;
  margin-left: 90px;
  width: 24px;
  height: 24px;
}

.icon-check {
  opacity: 1;
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

/* General mobile adjustments */
@media only screen and (max-width: 768px) {
    /* General container adjustments */
    .container, .main-content {
        width: 90%;
        margin: 0 auto;
        padding: 10px;
    }

    /* Navbar adjustments for mobile view */
    .navbar {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .navbar .menu {
        display: none; /* Hide regular menu */
    }
    .navbar .hamburger-menu {
        display: block;
        font-size: 24px;
        cursor: pointer;
    }
    
    /* Text adjustments */
    h1, h2, h3, h4 {
        font-size: 1.5em; /* Scale down header sizes */
        text-align: center;
    }
    p {
        font-size: 1em;
    }

    /* Image adjustments */
    img {
        width: 100%;
        height: auto;
    }

    /* Footer adjustments */
    .footer {
        text-align: center;
        padding: 10px;
        font-size: 0.9em;
    }
}

/* Smaller devices (e.g., phones) */
@media only screen and (max-width: 480px) {
    h1, h2, h3, h4 {
        font-size: 1.2em;
    }
    .container, .main-content {
        width: 100%;
        padding: 5px;
    }
    .footer {
        font-size: 0.8em;
    }
}
