/* Base styles */
.footer-container {
  background-color: #1e1e19;
  color: #ffffff;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.footer-sections {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
  width: 100%;
  padding-bottom: 20px;
}

.footer-social h4,
.footer-contact h4,
.footer-sponsors h4 {
  font-size: 1.5rem;
  font-family: "TitleFont";
  margin-bottom: 20px;
  text-align: center;
  background-color: #774120;
  border-radius: 8px;
  padding: 5px;
}

.footer-social,
.footer-contact,
.footer-sponsors {
  flex: 1;
  padding: 10px;
  text-align: right;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin-top: 45px;
}
/* Social icons hover effect */
.social-icons a svg {
  fill: #fff; /* Default color */
  transition: fill 0.3s ease-in-out;
}

.social-icons a.instagram:hover svg {
  fill: #e1306c; /* Instagram color */
}

.social-icons a.whatsapp:hover svg {
  fill: #25d366; /* WhatsApp color */
}

.social-icons a.youtube:hover svg {
  fill: #ff0000; /* YouTube color */
}

.social-icons a.telegram:hover svg {
  fill: #0088cc; /* Telegram color */
}

.social-icons a {
  width: 13%;
  transition: all 0.3s ease;
}
.social-icons a:hover {
  transform: scale(1.4);
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 20px;
  padding: 10px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  width: 96%;
  text-align: right;
}

.footer-bottom {
  padding: 10px;
  text-align: center;
  font-size: 0.9rem;
  border-top: 1px solid #444;
  margin-top: 20px;
  width: 100%;
}

/* Medium devices (e.g., tablets) */
@media (max-width: 768px) {
  .footer-container {
    padding: 20px;
  }

  .footer-sections {
    flex-direction: column-reverse;
    gap: 20px;
    padding-bottom: 10px;
  }

  .footer-social h4,
  .footer-contact h4,
  .footer-sponsors h4 {
    font-size: 1.5rem;
    padding: 5px;
  }

  .social-icons {
    gap: 35px;
    margin-top: 30px;
  }

  .social-icons a {
    width: 14%;
  }
  .social-icons a:hover {
    transform: scale(1.1);
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 1rem;
    width: 95%;
    padding: 10px;
  }

  .footer-bottom {
    font-size: 0.7rem;
    width: 95%;
  }
}

/* Small devices (e.g., phones) */
/* @media (max-width: 480px) {
  .footer-container {
    padding: 30px;
  }

  .footer-sections {
    gap: 10px;
    padding-bottom: 5px;
  }

  .footer-social h4,
  .footer-contact h4,
  .footer-sponsors h4 {
    font-size: 1rem;
    padding: 3px;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 0.8rem;
    padding: 5px;
  }

  .footer-bottom {
    font-size: 0.7rem;
    width: 90%;
  }
} */
