.country-info {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: right;
  flex-wrap: wrap;
  padding: 0 20px 0 0;
}

.flag-animation {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.separator {
  position: relative;
  font-size: 14rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: rgb(75, 75, 75);
}

.text-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  max-width: 55%;
  padding-bottom: 30px;
}

.country-name {
  font-family: "TitleFont", sans-serif;
  font-size: 3.5rem;
  color: #fff;
  margin-bottom: 10px;
}

.country-info-text {
  font-size: 1.5rem;
  color: #fff;
  text-align: justify;
}

/* General mobile adjustments */
@media only screen and (max-width: 768px) {
  /* General container adjustments */
  .country-info {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: nowrap;
    padding: 10px;
  }

  .country-info-text {
    font-size: 1rem;
    color: #fff;
    text-align: justify;
  }

  .country-name {
    font-family: "TitleFont", sans-serif;
    font-size: 3rem;
    color: #fff;
    margin-bottom: 10px;
  }

  .text-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
  }

  .flag-animation {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .separator {
    display: none;
  }
}

/* Smaller devices (e.g., phones) */
/* @media only screen and (max-width: 480px) {
  h1,
  h2,
  h3,
  h4 {
    font-size: 1.2em;
  }
  .container,
  .main-content {
    width: 100%;
    padding: 5px;
  }
  .footer {
    font-size: 0.8em;
  }
} */
